<template lang="pug">
    el-col(:span="10")
        div#D3.chart

</template>

<script>
var echarts = require("echarts");
import { getWidget } from "@/api/dashboard";
const id = "D3";
export default {
  name: id,
  async created() {
    const resp = await getWidget(id);
    const stats = resp.data.data.reverse();
    const days = stats.map(e => e.date);
    const amts = stats.map(e => e.amt);
    // 订单金额走势
    const sumChart = echarts.init(document.getElementById(id));
    sumChart.setOption({
      title: { text: "7日成交金额" },
      tooltip: {
        show: true
      },
      legend: {
        data: ["成交金额"],
        right: 1
      },
      yAxis: [
        {
          name: "成交金额",
          type: "value",
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: "#666",
            fontSize: 12
          }
        }
      ],
      xAxis: {
        axisTick: {
          show: false
        },
        axisLabel: {
          color: "#666",
          fontSize: 12,
          margin: 12
        },
        data: days
      },
      series: [
        {
          name: "订单数",
          yAxisIndex: 0,
          data: amts,
          type: "line",
          color: "#20B2AA"
        }
      ]
    });
  }
};
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
  padding: 30px;
}
</style>
